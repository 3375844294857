<template>
  <div class="topbar-item quick_create_app">
    <v-btn
      id="create-app-card-button"
      class="btn btn-start"
      @click="checkApp"
      v-if="smallBtn"
    >
      Let's start
    </v-btn>
    <v-btn
      :outlined="$vuetify.theme.dark"
      v-else
      color="primary"
      :loading="loading"
      @click="checkApp"
    >
      <v-icon left>mdi-plus</v-icon>
      {{ text }}
    </v-btn>
    <SidePanel v-model="drawer" width="600" :loading="busy">
      <div>
        <v-card-title class="display-1 justify-center mb-4 mt-n3">
          Add App
        </v-card-title>
        <div role="alert" class="alert fade alert-danger">
          <div class="alert-text text-left caption">{{ error }}</div>
        </div>
        <create-app-step
          from-create-panel
          @close="drawer = false"
        ></create-app-step>
      </div>
    </SidePanel>
    <Modal
      v-model="openStartTrialPopup"
      no-py
      @close="openStartTrialPopup = false"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 900 : ''"
    >
      <template #message>
        <start-trial-modal
          v-if="openStartTrialPopup"
          trigger-from="appProject"
          :message="'Your team has reached the maximum number of apps'"
          from-apps-page
          :tracker-event="trackerEvent"
          @open-subscribe="openSubscriptionModal = true"
          @close="openStartTrialPopup = false"
        ></start-trial-modal>
      </template>
    </Modal>
  </div>
</template>

<script>
import CreateAppStep from "@/view/components/App/CreateAppStep";
import { mapGetters } from "vuex";
import { CHECK_APP } from "@/store/apps/apps.module";
import StartTrialModal from "@/view/components/Common/StartTrialModal";

export default {
  name: "create-app-panel",
  props: {
    smallBtn: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Add App",
    },
  },
  components: { CreateAppStep, StartTrialModal },
  computed: {
    ...mapGetters({
      getTeamPlanLimits: "getTeamPlanLimits",
      getTotalAppsCount: "getTotalAppsCount",
      getTeamTrial: "getTeamTrial",
    }),
    IsActiveTrial() {
      return this.getTeamTrial && !this.getTeamTrial.status;
    },
    isAppsLimitReached() {
      if (this.getTeamPlanLimits && !this.getTeamPlanLimits.apps) {
        return false;
      } else {
        return (
          this.getTeamPlanLimits &&
          this.getTeamPlanLimits.apps &&
          this.getTotalAppsCount >= this.getTeamPlanLimits.apps
        );
      }
    },
  },
  data() {
    return {
      drawer: false,
      error: null,
      trackerEvent: {},
      openStartTrialPopup: false,
      busy: false,
      loading: false,
    };
  },
  created() {
    this.trackerEvent = {
      event: "apps",
      current: this.getTeamPlanLimits.apps,
      requested: 1,
    };
  },
  methods: {
    checkApp() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(CHECK_APP)
        .then(() => {
          this.drawer = !this.drawer;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.status === 402) {
            this.openStartTrialPopup = true;
          } else {
            this.notifyErrorMessage(err.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#quick_create_app {
  overflow: hidden;
}
.quick_create_app {
  .offcanvas {
    right: 0;
    &.offcanvas-right {
      right: -900px;
      &.offcanvas-on {
        right: 0;
      }
    }
  }
}
</style>
