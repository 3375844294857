var render = function render(){
  var _vm$RELEASE_STATUS$_v;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.showTestFlight && _vm.release ? _c('div', [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$release, _vm$release2, _vm$release2$platform, _vm$release3, _vm$release3$platform;
        var on = _ref.on;
        return [_c('v-chip', _vm._g({
          staticClass: "release-chip",
          class: (_vm$release = _vm.release) === null || _vm$release === void 0 ? void 0 : _vm$release.platform,
          attrs: {
            "small": ""
          }
        }, on), [((_vm$release2 = _vm.release) === null || _vm$release2 === void 0 ? void 0 : (_vm$release2$platform = _vm$release2.platform) === null || _vm$release2$platform === void 0 ? void 0 : _vm$release2$platform.toLowerCase()) === 'ios' ? _c('v-icon', {
          attrs: {
            "color": "#B3B3B3",
            "small": ""
          }
        }, [_vm._v("mdi-apple ")]) : _vm._e(), ((_vm$release3 = _vm.release) === null || _vm$release3 === void 0 ? void 0 : (_vm$release3$platform = _vm$release3.platform) === null || _vm$release3$platform === void 0 ? void 0 : _vm$release3$platform.toLowerCase()) === 'android' ? _c('android-logo', {
          staticClass: "mr-1"
        }) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.shortenVersion(_vm.release.version, _vm.versionLength)) + " (" + _vm._s(_vm.shortenVersion(_vm.release.version_code, _vm.versionCodeLength)) + ")")])], 1)];
      }
    }], null, false, 3226375327)
  }, [_c('ul', [_c('li', [_vm._v("Status: " + _vm._s(((_vm$RELEASE_STATUS$_v = _vm.RELEASE_STATUS[_vm.release.status]) === null || _vm$RELEASE_STATUS$_v === void 0 ? void 0 : _vm$RELEASE_STATUS$_v.title) || "-"))]), _c('li', [_vm._v("Version: " + _vm._s(_vm.release.version))]), _c('li', [_vm._v("Code: " + _vm._s(_vm.release.version_code))]), _c('li', [_vm._v("Size: " + _vm._s(_vm.formatBytes(_vm.release.size)))])])])], 1) : _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-chip', _vm._g({
          staticClass: "ios release-chip",
          attrs: {
            "small": ""
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.openTestFlightLink.apply(null, arguments);
            }
          }
        }, on), [_c('v-icon', {
          attrs: {
            "color": "#B3B3B3",
            "small": "",
            "left": ""
          }
        }, [_vm._v("mdi-apple ")]), _c('span', {
          staticClass: "font-weight-regular"
        }, [_vm._v(" Testflight ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.testFlightLink) + " ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }