var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "topbar-item quick_create_app"
  }, [_vm.smallBtn ? _c('v-btn', {
    staticClass: "btn btn-start",
    attrs: {
      "id": "create-app-card-button"
    },
    on: {
      "click": _vm.checkApp
    }
  }, [_vm._v(" Let's start ")]) : _c('v-btn', {
    attrs: {
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.checkApp
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.text) + " ")], 1), _c('SidePanel', {
    attrs: {
      "width": "600",
      "loading": _vm.busy
    },
    model: {
      value: _vm.drawer,
      callback: function callback($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }, [_c('div', [_c('v-card-title', {
    staticClass: "display-1 justify-center mb-4 mt-n3"
  }, [_vm._v(" Add App ")]), _c('div', {
    staticClass: "alert fade alert-danger",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-text text-left caption"
  }, [_vm._v(_vm._s(_vm.error))])]), _c('create-app-step', {
    attrs: {
      "from-create-panel": ""
    },
    on: {
      "close": function close($event) {
        _vm.drawer = false;
      }
    }
  })], 1)]), _c('Modal', {
    attrs: {
      "no-py": "",
      "fullscreen": _vm.$vuetify.breakpoint.mdAndDown,
      "width": _vm.$vuetify.breakpoint.mdAndUp ? 900 : ''
    },
    on: {
      "close": function close($event) {
        _vm.openStartTrialPopup = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_vm.openStartTrialPopup ? _c('start-trial-modal', {
          attrs: {
            "trigger-from": "appProject",
            "message": 'Your team has reached the maximum number of apps',
            "from-apps-page": "",
            "tracker-event": _vm.trackerEvent
          },
          on: {
            "open-subscribe": function openSubscribe($event) {
              _vm.openSubscriptionModal = true;
            },
            "close": function close($event) {
              _vm.openStartTrialPopup = false;
            }
          }
        }) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openStartTrialPopup,
      callback: function callback($$v) {
        _vm.openStartTrialPopup = $$v;
      },
      expression: "openStartTrialPopup"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }