<template>
  <div>
    <div v-if="!showTestFlight && release">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-chip
            small
            :class="release?.platform"
            class="release-chip"
            v-on="on"
          >
            <v-icon
              v-if="release?.platform?.toLowerCase() === 'ios'"
              color="#B3B3B3"
              small
              >mdi-apple
            </v-icon>
            <android-logo
              v-if="release?.platform?.toLowerCase() === 'android'"
              class="mr-1"
            />
            <span
              >{{ shortenVersion(release.version, versionLength) }} ({{
                shortenVersion(release.version_code, versionCodeLength)
              }})</span
            >
          </v-chip>
        </template>
        <ul>
          <li>Status: {{ RELEASE_STATUS[release.status]?.title || "-" }}</li>
          <li>Version: {{ release.version }}</li>
          <li>Code: {{ release.version_code }}</li>
          <li>Size: {{ formatBytes(release.size) }}</li>
        </ul>
      </v-tooltip>
    </div>
    <v-tooltip bottom v-else>
      <template #activator="{ on }">
        <v-chip
          @click.stop="openTestFlightLink"
          small
          v-on="on"
          class="ios release-chip"
        >
          <v-icon color="#B3B3B3" small left>mdi-apple </v-icon>
          <span class="font-weight-regular"> Testflight </span>
        </v-chip>
      </template>
      <span>
        {{ testFlightLink }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import { formatBytes, shortenVersion } from "@/core/services/helper.service";
import { releaseStatus } from "@/consts/statuses";

export default {
  props: {
    release: {
      type: Object,
      default: null,
    },
    versionLength: {
      type: Number,
      default: 6,
    },
    versionCodeLength: {
      type: Number,
      default: 6,
    },
    showTestFlight: {
      type: Boolean,
      default: false,
    },
    testFlightLink: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      RELEASE_STATUS: releaseStatus,
    };
  },
  methods: {
    formatBytes,
    shortenVersion,
    openTestFlightLink() {
      window.open(this.testFlightLink, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.release-chip {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  &.ios {
    background: rgba(179, 179, 179, 0.12);
    color: rgba(0, 0, 0, 0.6);
  }

  &.android {
    background: rgb(66, 212, 126, 0.12);
    color: #42d47e;
  }
}
</style>
