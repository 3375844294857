<template>
    <v-card class="pa-10">
        <v-row>
            <template v-if="getIsNetworkError">
                <v-col class="text-center titleFont bold-text" cols="12">
                    Network connection error
                </v-col>
                <v-col class="text-left" cols="12">
                    <v-alert label outlined color="error">
                        <span v-html="getIsNetworkError"></span>
                    </v-alert>
                </v-col>
                <v-col class="text-center" cols="12">
                    <v-btn @click="reloadPage" color="primary" :outlined="$vuetify.theme.dark" class="mr-3 text-transform-none">
                        Reload page
                    </v-btn>
                </v-col>
            </template>
            <template v-else>
                <v-col class="text-center titleFont bold-text" cols="12">
                    {{ title }}
                </v-col>
                <v-col class="text-center" cols="12">
                    <v-alert label outlined :color="chipColor">
                        <v-icon left :color="chipColor" v-text="'mdi-information-outline'"></v-icon>
                        <span v-html="message"></span>
                    </v-alert>
                </v-col>
                <v-col v-if="!noSecondMessage" class="text-center" cols="12">
                    Please, contact the person who share this link with you for more info
                </v-col>
                <v-col class="mb-14" cols="12">
                    <inline-svg class="mt-6" :max-height="150" :src="'/media/placeholders/all-done.svg'" />
                </v-col>
                <v-col class="text-center" cols="12">
                    <v-btn @click="redirectToWebsite" color="primary" class="mr-3 text-transform-none">
                        <v-img src="/media/logos/icon-light.svg" height="20" width="20" class="mr-4" contain></v-img>
                        To the website
                    </v-btn>
                    <v-btn @click="$router.push({ name: 'dashboard' })" color="primary" class="text-transform-none" outlined>
                        <v-img src="/media/logos/small_logo.png" class="mr-4"></v-img>
                        To the Dashboard
                    </v-btn>
                </v-col>
            </template>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        message: {
            type: String,
            default: "",
        },
        networkError: {
            type: Boolean,
            default: false,
        },
        noSecondMessage: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "Invalid Request",
        },
        chipColor: {
            type: String,
            default: "error",
        },
    },
    computed: {
        ...mapGetters({
            getIsNetworkError: "getIsNetworkError",
        }),
    },
    methods: {
        reloadPage() {
            window.location.reload();
        },
        redirectToWebsite() {
            window.open("https://testapp.io/");
        },
    },
};
</script>
