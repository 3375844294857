<template>
  <div>
    <div v-if="getInactiveUserMessage || getIsNetworkError">
      <no-data
        v-if="getIsUserDisabled || getInactiveUserMessage"
        :first-text="getInactiveUserMessage"
      ></no-data>
      <vue-no-data v-else :message="getInactiveUserMessage"></vue-no-data>
    </div>
    <div v-else>
      <v-row align="center">
        <v-col cols="12" md="4">
          <AppAutoComplete
            v-if="totalAppCount > 10"
            ref="app-autocomplete"
            :current-app="{}"
            :default="{}"
            class="mb-2"
            from-app-search
            label="Search Apps"
            @input="selectedApp = $event"
            @openApp="$emit('openApp')"
          />
        </v-col>
        <v-col v-if="canManageApp" class="mt-4" cols="12" md="8">
          <div class="float-right">
            <create-app-panel ref="create_app_panel"></create-app-panel>
          </div>
        </v-col>
        <v-col v-if="$route?.query?.to" cols="12" md="3">
          <v-alert outlined color="primary">
            <v-icon
              color="primary"
              left
              v-text="'mdi-information-slab-circle-outline'"
            ></v-icon
            >Select the app to continue...
          </v-alert>
        </v-col>
        <v-col cols="12">
          <DatatableWrapper
            :loading="appLoading"
            :pagination="pagination"
            clickable-row
            title="Apps"
          >
            <v-data-table
              :headers="appHeaders"
              :items="appData"
              :loading="appLoading"
              disable-sort
              mobile-breakpoint="0"
              hide-default-footer
              @click:row="navigateToAppInfo($event)"
            >
              <template #item.name="{ item }">
                <v-row align="center" class="cursor-pointer py-4">
                  <v-avatar rounded size="40">
                    <img
                      :alt="item.name"
                      :src="getAppImage(item)"
                      width="50"
                      class="rounded"
                      @error="replaceDefaultAppImage"
                    />
                  </v-avatar>

                  <span class="mx-4">{{ item.name }}</span>
                </v-row>
              </template>
              <template #item.latest_releases="{ item }">
                <div class="mb-2" v-if="item?.latest_releases?.ios?.version">
                  <release-chip
                    :release="{
                      ...item?.latest_releases?.ios,
                      platform: 'ios',
                    }"
                  ></release-chip>
                </div>
                <div v-if="item?.latest_releases?.android?.version">
                  <release-chip
                    :release="{
                      ...item?.latest_releases?.android,
                      platform: 'android',
                    }"
                  ></release-chip>
                </div>
              </template>
              <template #item.releases="{ item }">
                {{ item?.stats?.releases?.total }}
              </template>
              <template #item.comments="{ item }">
                {{ item?.stats?.releases?.comments }}
              </template>
              <template #item.sessions="{ item }">
                {{ item?.stats?.sessions?.total }}
              </template>
              <template #item.feedback="{ item }">
                {{ item?.stats?.sessions?.feedback }}
              </template>
              <template #item.members="{ item }">
                <AvatarGroup
                  :data="item.members.data"
                  :app-id="item.id"
                  :total="item.members.total"
                />
              </template>
              <template #item.action>
                <v-icon
                  color="info"
                  class="cursor-pointer"
                  v-text="'mdi-arrow-right'"
                ></v-icon>
              </template>
              <template #no-data>
                <no-data
                  v-if="!canManageApp"
                  :image="
                    $vuetify.theme.dark
                      ? 'app_default_light'
                      : 'apps_empty_state'
                  "
                  first-text="Welcome Aboard!"
                  second-text="<p>No apps available currently. Reach out to your team admin for access, ensure you're in the right team space, or complete onboarding steps. Your journey is just about to begin!</p>"
                ></no-data>
                <no-data
                  v-else
                  class="text-left"
                  :image="
                    $vuetify.theme.dark
                      ? 'app_default_light'
                      : 'apps_empty_state'
                  "
                  btn-icon=""
                  btn-text=""
                  first-text="It all starts from here!"
                  second-text="<p><strong>Kickstart Your Journey!</strong> Create apps for Android & iOS, collaborate with team members, manage releases, and dive into session insights. It's the start of something amazing...</p>"
                ></no-data>
              </template>
            </v-data-table>
          </DatatableWrapper>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import AvatarGroup from "@/view/components/Team/Members/AvatarGroup/AvatarGroup";
import { get, isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import { GET_APPS, JOIN_APP, SEARCH } from "@/store/apps/apps.module";
// import InfinateLoadingBase from "@/view/components/Common/InfinateLoadingBase.vue";
import VueNoData from "@/view/components/Common/VueNoData";
import CreateAppPanel from "@/view/components/App/CreateAppPanel";
import AppAutoComplete from "@/view/components/Common/AppSearch";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";
import ReleaseChip from "@/view/components/Common/ReleaseChip";
// import EmptyStateDemo from "@/view/components/Common/EmptyStateDemo";

export default {
  name: "apps",
  data() {
    return {
      get,
      isEmpty,
      search: "",
      selectedApp: {},
      appLoading: false,
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
      appData: [],
      endpoint: GET_APPS,
      totalAppCount: 0,
      breadCrumbs: [{ text: "Apps", disabled: false, to: { name: "apps" } }],
      // in case search not found
      noSearchFound: null,
      loading: false,
      errorMessage: null,
      totalApp: 0,
      noApps: false,
    };
  },
  computed: {
    ...mapState({
      apps: (state) => state.apps.filteredApps,
      totalApps: (state) => state.apps.totalApps,
      appsLoader: (state) => state.apps.appsLoading,
    }),
    ...mapGetters({
      getApps: "getApps",
      getInactiveUserMessage: "getInactiveUserMessage",
      getIsNetworkError: "getIsNetworkError",
    }),
    headers() {
      return [
        {
          text: "Name",
          sortable: false,
          permissions: true,
          width: "250",
          value: "name",
        },
        {
          text: "Latest Release",
          sortable: false,
          permissions: true,
          value: "latest_releases",
        },
        {
          text: "Releases",
          sortable: false,
          permissions: true,
          value: "releases",
        },
        {
          text: "Comments",
          sortable: false,
          permissions: this.canManageApp,
          value: "comments",
        },
        {
          text: "Sessions",
          sortable: false,
          permissions: this.canManageApp,
          value: "sessions",
        },
        {
          text: "Feedback",
          sortable: false,
          permissions: this.canManageApp,
          value: "feedback",
        },
        {
          text: "Members",
          sortable: false,
          permissions: this.canManageApp,
          value: "members",
        },
        {
          text: "",
          sortable: false,
          permissions: true,
          width: 40,
          value: "action",
        },
      ];
    },
    appHeaders() {
      return this.headers.filter((header) => !!header.permissions);
    },
    lastId() {
      return this.apps && this.apps.length
        ? this.apps[this.apps.length - 1].id
        : "";
    },
  },
  watch: {
    selectedApp(val) {
      if (val && val.id) {
        this.$router.push({
          name: "app-release-index",
          params: { app_id: val.id },
        });
      }
    },
    async "pagination.page"(newPage) {
      await this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      });

      this.pagination.page = newPage;
      this.getAllAppData({ page: newPage, last_id: this.lastId });
    },
    totalAppCount(value) {
      this.pagination.totalItems = value;
    },
  },
  components: {
    ReleaseChip,
    DatatableWrapper,
    // ReleaseEmptyState,
    AppAutoComplete,
    CreateAppPanel,
    VueNoData,
    // InfinateLoadingBase,
    AvatarGroup,
  },
  created() {
    window.scroll(0, 50);
    this.getAllAppData();
    this.clearAppResponse();
  },
  metaInfo() {
    return {
      title: "TestApp.io - Portal - Apps",
      meta: [
        {
          name: "description",
          content: `A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!`,
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setAppResponse: "setAppResponse",
      clearAppResponse: "clearAppResponse",
    }),
    getAllAppData({ page = this.pagination.page, last_id = "" } = {}) {
      this.appData = [];
      if (this.appLoading) return;
      this.appLoading = true;
      const params = {
        page,
        last_id,
      };
      this.$portalApi
        .get(`${this.apiRoutes.portal.app.list}?${this.getQuery(params)}`)
        .then(({ data }) => {
          this.appData = data.data ?? [];
          this.totalAppCount = data.meta.total ?? 0;
          if (this.$vuetify.theme.dark) {
            this.$vuetify.theme.dark = true;
          }
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        })
        .finally(() => {
          this.appLoading = false;
        });
    },
    createAppDrawer() {
      this.$refs["create_app_panel"].checkApp();
    },
    setInfiniteResponse(data) {
      this.setAppResponse(data.data);
    },
    resetInfiniteResponse() {
      this.clearAppResponse();
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    navigateToAppInfo(detail) {
      this.$router.push({
        name: this.$route.query.to || "app-dashboard",
        params: {
          app_id: detail.id,
          tab: this.$route.query.tab || "",
        },
      });
    },
    joinApp(app) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(JOIN_APP, app.id)
        .then((response) => {
          this.loading = false;
          this.notifyUserMessage({ message: response.message });
          this.$router.push({
            name: "app-dashboard",
            params: { app_id: app.id },
          });
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    onSearch() {
      this.$store.dispatch(SEARCH, this.search);
      isEmpty(this.apps)
        ? (this.noSearchFound = true)
        : (this.noSearchFound = false);
    },
  },
};
</script>

<style scoped>
.overlay {
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: rgba(181, 181, 195, 0.3);
}
</style>
