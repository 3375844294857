var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "pa-10"
  }, [_c('v-row', [_vm.getIsNetworkError ? [_c('v-col', {
    staticClass: "text-center titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Network connection error ")]), _c('v-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "label": "",
      "outlined": "",
      "color": "error"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.getIsNetworkError)
    }
  })])], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.reloadPage
    }
  }, [_vm._v(" Reload page ")])], 1)] : [_c('v-col', {
    staticClass: "text-center titleFont bold-text",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-alert', {
    attrs: {
      "label": "",
      "outlined": "",
      "color": _vm.chipColor
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": _vm.chipColor
    },
    domProps: {
      "textContent": _vm._s('mdi-information-outline')
    }
  }), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })], 1)], 1), !_vm.noSecondMessage ? _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" Please, contact the person who share this link with you for more info ")]) : _vm._e(), _c('v-col', {
    staticClass: "mb-14",
    attrs: {
      "cols": "12"
    }
  }, [_c('inline-svg', {
    staticClass: "mt-6",
    attrs: {
      "max-height": 150,
      "src": '/media/placeholders/all-done.svg'
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "mr-3 text-transform-none",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.redirectToWebsite
    }
  }, [_c('v-img', {
    staticClass: "mr-4",
    attrs: {
      "src": "/media/logos/icon-light.svg",
      "height": "20",
      "width": "20",
      "contain": ""
    }
  }), _vm._v(" To the website ")], 1), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_c('v-img', {
    staticClass: "mr-4",
    attrs: {
      "src": "/media/logos/small_logo.png"
    }
  }), _vm._v(" To the Dashboard ")], 1)], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }