var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "avatars-group stacked",
    staticStyle: {
      "overflow": "visible"
    }
  }, [_vm._l(_vm.avatarsStackedLimited, function (avatar) {
    return _c('div', {
      key: avatar.id,
      staticClass: "avatars-group__item"
    }, [_c('user-avatar', {
      attrs: {
        "user-detail": avatar
      }
    })], 1);
  }), _vm.total > _vm.stackedLimit ? _c('div', {
    staticClass: "avatars-group__item mt-1"
  }, [_c('v-avatar', {
    staticClass: "grey",
    attrs: {
      "size": "30"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.$router.push({
          name: 'app-settings-members',
          params: {
            app_id: _vm.appId
          }
        });
      }
    }
  }, [_c('span', {
    staticClass: "font-weight-light text-caption pointer"
  }, [_vm._v(" +" + _vm._s(_vm.total - _vm.stackedLimit) + " ")])])], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }