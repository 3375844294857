var render = function render(){
  var _vm$$route, _vm$$route$query;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInactiveUserMessage || _vm.getIsNetworkError ? _c('div', [_vm.getIsUserDisabled || _vm.getInactiveUserMessage ? _c('no-data', {
    attrs: {
      "first-text": _vm.getInactiveUserMessage
    }
  }) : _c('vue-no-data', {
    attrs: {
      "message": _vm.getInactiveUserMessage
    }
  })], 1) : _c('div', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_vm.totalAppCount > 10 ? _c('AppAutoComplete', {
    ref: "app-autocomplete",
    staticClass: "mb-2",
    attrs: {
      "current-app": {},
      "default": {},
      "from-app-search": "",
      "label": "Search Apps"
    },
    on: {
      "input": function input($event) {
        _vm.selectedApp = $event;
      },
      "openApp": function openApp($event) {
        return _vm.$emit('openApp');
      }
    }
  }) : _vm._e()], 1), _vm.canManageApp ? _c('v-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('create-app-panel', {
    ref: "create_app_panel"
  })], 1)]) : _vm._e(), (_vm$$route = _vm.$route) !== null && _vm$$route !== void 0 && (_vm$$route$query = _vm$$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.to ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    },
    domProps: {
      "textContent": _vm._s('mdi-information-slab-circle-outline')
    }
  }), _vm._v("Select the app to continue... ")], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('DatatableWrapper', {
    attrs: {
      "loading": _vm.appLoading,
      "pagination": _vm.pagination,
      "clickable-row": "",
      "title": "Apps"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.appHeaders,
      "items": _vm.appData,
      "loading": _vm.appLoading,
      "disable-sort": "",
      "mobile-breakpoint": "0",
      "hide-default-footer": ""
    },
    on: {
      "click:row": function clickRow($event) {
        return _vm.navigateToAppInfo($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-row', {
          staticClass: "cursor-pointer py-4",
          attrs: {
            "align": "center"
          }
        }, [_c('v-avatar', {
          attrs: {
            "rounded": "",
            "size": "40"
          }
        }, [_c('img', {
          staticClass: "rounded",
          attrs: {
            "alt": item.name,
            "src": _vm.getAppImage(item),
            "width": "50"
          },
          on: {
            "error": _vm.replaceDefaultAppImage
          }
        })]), _c('span', {
          staticClass: "mx-4"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }, {
      key: "item.latest_releases",
      fn: function fn(_ref2) {
        var _item$latest_releases, _item$latest_releases2, _item$latest_releases3, _item$latest_releases4, _item$latest_releases5, _item$latest_releases6;
        var item = _ref2.item;
        return [item !== null && item !== void 0 && (_item$latest_releases = item.latest_releases) !== null && _item$latest_releases !== void 0 && (_item$latest_releases2 = _item$latest_releases.ios) !== null && _item$latest_releases2 !== void 0 && _item$latest_releases2.version ? _c('div', {
          staticClass: "mb-2"
        }, [_c('release-chip', {
          attrs: {
            "release": Object.assign({}, item === null || item === void 0 ? void 0 : (_item$latest_releases3 = item.latest_releases) === null || _item$latest_releases3 === void 0 ? void 0 : _item$latest_releases3.ios, {
              platform: 'ios'
            })
          }
        })], 1) : _vm._e(), item !== null && item !== void 0 && (_item$latest_releases4 = item.latest_releases) !== null && _item$latest_releases4 !== void 0 && (_item$latest_releases5 = _item$latest_releases4.android) !== null && _item$latest_releases5 !== void 0 && _item$latest_releases5.version ? _c('div', [_c('release-chip', {
          attrs: {
            "release": Object.assign({}, item === null || item === void 0 ? void 0 : (_item$latest_releases6 = item.latest_releases) === null || _item$latest_releases6 === void 0 ? void 0 : _item$latest_releases6.android, {
              platform: 'android'
            })
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "item.releases",
      fn: function fn(_ref3) {
        var _item$stats, _item$stats$releases;
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats = item.stats) === null || _item$stats === void 0 ? void 0 : (_item$stats$releases = _item$stats.releases) === null || _item$stats$releases === void 0 ? void 0 : _item$stats$releases.total) + " ")];
      }
    }, {
      key: "item.comments",
      fn: function fn(_ref4) {
        var _item$stats2, _item$stats2$releases;
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats2 = item.stats) === null || _item$stats2 === void 0 ? void 0 : (_item$stats2$releases = _item$stats2.releases) === null || _item$stats2$releases === void 0 ? void 0 : _item$stats2$releases.comments) + " ")];
      }
    }, {
      key: "item.sessions",
      fn: function fn(_ref5) {
        var _item$stats3, _item$stats3$sessions;
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats3 = item.stats) === null || _item$stats3 === void 0 ? void 0 : (_item$stats3$sessions = _item$stats3.sessions) === null || _item$stats3$sessions === void 0 ? void 0 : _item$stats3$sessions.total) + " ")];
      }
    }, {
      key: "item.feedback",
      fn: function fn(_ref6) {
        var _item$stats4, _item$stats4$sessions;
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$stats4 = item.stats) === null || _item$stats4 === void 0 ? void 0 : (_item$stats4$sessions = _item$stats4.sessions) === null || _item$stats4$sessions === void 0 ? void 0 : _item$stats4$sessions.feedback) + " ")];
      }
    }, {
      key: "item.members",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('AvatarGroup', {
          attrs: {
            "data": item.members.data,
            "app-id": item.id,
            "total": item.members.total
          }
        })];
      }
    }, {
      key: "item.action",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "color": "info"
          },
          domProps: {
            "textContent": _vm._s('mdi-arrow-right')
          }
        })];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function fn() {
        return [!_vm.canManageApp ? _c('no-data', {
          attrs: {
            "image": _vm.$vuetify.theme.dark ? 'app_default_light' : 'apps_empty_state',
            "first-text": "Welcome Aboard!",
            "second-text": "<p>No apps available currently. Reach out to your team admin for access, ensure you're in the right team space, or complete onboarding steps. Your journey is just about to begin!</p>"
          }
        }) : _c('no-data', {
          staticClass: "text-left",
          attrs: {
            "image": _vm.$vuetify.theme.dark ? 'app_default_light' : 'apps_empty_state',
            "btn-icon": "",
            "btn-text": "",
            "first-text": "It all starts from here!",
            "second-text": "<p><strong>Kickstart Your Journey!</strong> Create apps for Android & iOS, collaborate with team members, manage releases, and dive into session insights. It's the start of something amazing...</p>"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }